<template>
    <div class="onlinemsg">
        <full-scroll></full-scroll>
        <div class="
                msg-title
                flex flex-justify-between flex-pack-center
                color_FF
            ">
            <div class="flex flex-pack-center flex-1">
                <img src="@/assets/national_emblem.png" alt="" class="msg-national m_r20" />
                <div class="text_align_last">
                    <p class="font-size24 m_b10">广阳镇人大代表之家</p>
                    <p class="font-size12">
                        GUANG YANG ZHEN REN DA DAI BIAO ZHI JIA
                    </p>
                </div>
            </div>
            <div class="msg-title-mid-box font-size32 font-blod flex-1">
                <img src="@/assets/images/tot-top-title.png" alt="" />
                <div class="msg-title-mid-box-title">{{ $route.query.sourceType == 2 ? '民生实事' : $route.query.sourceType ==
                    1 ? '连心桥' : $route.query.sourceType == 4 ? '微心愿' : '' }}</div>
            </div>
            <div class="flex-end flex-1 flex" @click="backHome">
                <div class="my_text_center">
                    <img src="@/assets/images/backhome-new.png" alt="" class="msg-backhome m_b10" />
                    <p>返回首页</p>
                </div>
            </div>
        </div>
        <div class="msg-mid flex flex-justify-center flex-pack-center">
            <!-- <div
                class="
                    msg-mid-but
                    flex flex-v flex-justify-center flex-pack-center
                    color_FF
                "
                @click="goToVideomsg"
            >
                <img src="@/assets/images/videomsg.png" alt="" />
                <p class="font-size28">录像留言</p>
            </div> -->
            <div class="
                    msg-mid-but
                    color_FF
                    flex flex-v flex-justify-center flex-pack-center
                " @click="goToWordmsg" v-if="$route.query.sourceType == 1 || $route.query.sourceType == 2">
                <img src="@/assets/images/wordmsg.png" alt="" />
                <p class="font-size28">文字留言</p>
            </div>
            <div class="
                    msg-mid-but
                    color_FF
                    flex flex-v flex-justify-center flex-pack-center
                " @click="goToRadiomsg" v-if="$route.query.sourceType == 1 || $route.query.sourceType == 2">
                <img src="@/assets/images/211-1.png" alt="" />
                <p class="font-size28">录音留言</p>
            </div>
            <div class="
                    msg-mid-but
                    color_FF
                    flex flex-v flex-justify-center flex-pack-center
                " @click="goTomakeawish" v-if="$route.query.sourceType == 4">
                <img src="@/assets/images/makeawish.png" alt="" />
                <!-- <p class="font-size28">许心愿</p> -->
            </div>
            <div class="
                    msg-mid-but
                    color_FF
                    flex flex-v flex-justify-center flex-pack-center
                " @click="goToLeadingawish" v-if="$route.query.sourceType == 4">
                <img src="@/assets/images/Leadingawish.png" alt="" />
                <!-- <p class="font-size28">领心愿</p> -->
            </div>
            <div class="
                    msg-mid-but
                    color_FF
                    flex flex-v flex-justify-center flex-pack-center
                " @click="goTowishwall" v-if="$route.query.sourceType == 4">
                <img src="@/assets/images/wishwall.png" alt="" />
                <!-- <p class="font-size28">心愿墙</p> -->
            </div>
        </div>
        <div class="
                msg-bottom
                flex flex-justify-between flex-pack-center
                font-size28
            ">
            <div class="my_text_center" @click="$router.replace('/peopleopinion')">
                <img src="@/assets/images/return-new.png" alt="" class="msg-return m_b10" />
                <p class="font-size24 color_FF">返回上级</p>
            </div>
            <div class="font-blod my_text_center color_FF">
            </div>

            <div class="my_text_center color_FF">
                <p class="font-size24 m_b10 font-blod">{{ todayMtime }}</p>
                <p class="font-size14">{{ todayTime }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { dateTimeFormat } from "@/request/until.js";
import fullScroll from "@/components/fullScroll.vue";

export default {
    components: { fullScroll },
    data() {
        return {
            todayTime: "",
            todayMtime: "",
            sourceType: null,
        };
    },
    mounted() { },
    created() {
        this.sourceType = this.$route.query.sourceType;
        this.getTime();
        this.timer = setInterval(() => {
            this.getTime();
        }, 1000);
    },
    methods: {
        getTime() {
            let _this = this;
            let time = "";
            time = dateTimeFormat(new Date());
            _this.todayTime = time.split(" ")[0];
            _this.todayMtime = time.split(" ")[1].slice(0, 5);
        },

        goToVideomsg() {
            this.$router.push({
                path: "/textvideo",
                query: { sourceType: this.sourceType },
            });
        },

        goToWordmsg() {
            this.$router.push({
                path: "/wordmsg",
                query: { sourceType: this.sourceType },
            });
        },

        goToRadiomsg() {
            this.$router.push({
                path: "/audionew",
                query: { sourceType: this.sourceType },
            });
        },
        goTomakeawish() {
            this.$router.push({
                path: "/wordMsg",
                query: { sourceType: this.sourceType },
            })
        },
        goToLeadingawish() {
            this.$router.push({
                path: "/Leadingawish",
                query: { sourceType: this.sourceType },
            })
        },

        goTowishwall(){
            this.$router.push({
                path: "/laws",
                query: { sourceType: this.sourceType },
            })
        },
        //返回首页
        backHome() {
            this.$router.push("/");
        },
        goBack() {
            this.$router.back();
        },
    },
};
</script>
